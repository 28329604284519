import { FetchItemCacheKey, FetchItemsGqlQuery } from '../../../../types';

import {
  FetchDownloadsGqlQuery,
  FetchDownloadsCacheKey,
  FetchDownloadsFilters,
  FetchDownloadsSort,
  FetchDownloadsPage,
  FetchDownloadsLimit
} from '../../downloadsTypes';

import {
  useIndexQuery,
  IndexQueryDefaultOptionsOpts
} from '../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  INITIAL_DOWNLOADS_FILTERS,
  INITIAL_DOWNLOADS_LIMIT,
  INITIAL_DOWNLOADS_PAGE,
  INITIAL_DOWNLOADS_SORT
} from '../../downloadsConstants';

interface DownloadsDefaultOptions<ItemType> {
  cacheKey: FetchDownloadsCacheKey;
  query: FetchDownloadsGqlQuery;
  initialFilters?: FetchDownloadsFilters;
  initialSort?: FetchDownloadsSort;
  initialPage?: FetchDownloadsPage;
  initialLimit?: FetchDownloadsLimit;
  options?: IndexQueryDefaultOptionsOpts<ItemType>;
}

interface DownloadsWithPrefetchItemOptions {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemQuery: FetchItemsGqlQuery;
}

interface DownloadsWithoutPrefetchItemOptions {
  fetchItemCacheKey?: never;
  fetchItemQuery?: never;
}

type DownloadsOptions<ItemType> = DownloadsDefaultOptions<ItemType> &
  (DownloadsWithPrefetchItemOptions | DownloadsWithoutPrefetchItemOptions);

const scope = 'downloads';

function usePaginatedDownloads<ItemType>({
  cacheKey,
  query,
  initialFilters = INITIAL_DOWNLOADS_FILTERS,
  initialSort = INITIAL_DOWNLOADS_SORT,
  initialPage = INITIAL_DOWNLOADS_PAGE,
  initialLimit = INITIAL_DOWNLOADS_LIMIT,
  fetchItemCacheKey,
  fetchItemQuery,
  options = {}
}: DownloadsOptions<ItemType>) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    clearItemsFilters,
    filterItems,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems,
    prefetchItem
  } = useIndexQuery<ItemType>({
    scope,
    query,
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    fetchItemCacheKey,
    fetchItemQuery,
    options
  });

  return {
    downloadsData: data,
    downloads: items,
    downloadsError: itemsError,
    downloadsTotalCount: itemsTotalCount,
    downloadsFetched: isFetched,
    downloadsLoading: isLoading,
    downloadsIsPlaceholderData: isPlaceholderData,
    downloadsFilters: currentFilters,
    downloadsSort: currentSort,
    downloadsPage: currentPage,
    downloadsLimit: currentLimit,
    filterDownloads: filterItems,
    clearDownloadsFilters: clearItemsFilters,
    sortDownloads: sortItems,
    paginateDownloads: paginateItems,
    limitDownloads: limitItems,
    prefetchDownloads: prefetchItems,
    prefetchDownload: prefetchItem
  };
}

export default usePaginatedDownloads;
