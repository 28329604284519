import React, { Fragment, ReactNode } from 'react';

import { FetchItemError, FetchItemsError } from '../../../types';

import { ErrorPage } from '../../../app/components/ErrorPage';

import { checkClientErrorErrorsStatus } from '../../../utils/parseRequestError/utils/checkClientErrorErrorsStatus';
import { clientErrorTypeDef } from '../../../utils/parseRequestError/utils/clientErrorTypeDef';

interface CheckErrorLayoutProps {
  error?: FetchItemError | FetchItemsError;
  children: ReactNode;
}

function CheckErrorLayout({ error, children }: CheckErrorLayoutProps) {
  if (checkClientErrorErrorsStatus(error, { status_code: 404 })) {
    return <ErrorPage statusCode={404} />;
  }

  if (checkClientErrorErrorsStatus(error, { status_code: 403 })) {
    return <ErrorPage statusCode={403} />;
  }

  if (clientErrorTypeDef(error) && error?.response?.status === 500) {
    return <ErrorPage statusCode={500} />;
  }

  return <Fragment>{children}</Fragment>;
}
export default CheckErrorLayout;
