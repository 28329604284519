import { useQuery, useQueryClient } from 'react-query';

import { DownloadCache } from '../../DownloadCache';

const downloadManagerBlockCacheKey = 'download-manager-block-opened';

const downloadManagerBlockOpened = false;

function useDownloadManagerBlockState() {
  const queryClient = useQueryClient();
  const { data } = useQuery<boolean>(
    downloadManagerBlockCacheKey,
    () => downloadManagerBlockOpened
  );

  const handleOpenDownloadManagerBlock = () => {
    queryClient.setQueryData<boolean>(downloadManagerBlockCacheKey, true);
    return queryClient.invalidateQueries(DownloadCache.indexCacheKey());
  };

  return {
    downloadManagerBlockOpened: data,
    openDownloadManagerBlock: handleOpenDownloadManagerBlock,
    closeDownloadManagerBlock: () =>
      queryClient.setQueryData<boolean>(downloadManagerBlockCacheKey, false)
  };
}

export default useDownloadManagerBlockState;
