import React, { ReactNode } from 'react';

import { I18nText, FetchItemError, FetchItemsError } from '../../../../types';

import { Permissions } from '../../../../common/permissions';

import { CheckErrorLayout } from '../../../../common/layouts/CheckErrorLayout';
import { CheckPermissionLayout } from '../../../../common/layouts/CheckPermissionLayout';

import { DownloadManagerBlock } from '../../../downloads/components/blocks/DownloadManagerBlock';

import { HeadTitle } from '../../../../helpers/HeadTitle';

interface MainLayoutProps {
  action: Permissions | string;
  children: ReactNode;
  i18nTitle?: I18nText;
  title?: string;
  error?: FetchItemError | FetchItemsError;
}

function MainLayout({
  action,
  i18nTitle,
  title,
  children,
  error
}: MainLayoutProps) {
  return (
    <CheckErrorLayout error={error}>
      <CheckPermissionLayout
        action={action}
        appAction={Permissions.MAIN_ACCESS}
      >
        {i18nTitle ? <HeadTitle i18nTitle={i18nTitle} /> : null}
        {title ? <HeadTitle i18nTitle={title} /> : null}

        <div
          id="main-section"
          className="flex-1 flex flex-col overflow-hidden relative sm:z-0"
        >
          {children}
          <DownloadManagerBlock />
        </div>
      </CheckPermissionLayout>
    </CheckErrorLayout>
  );
}

export default MainLayout;
