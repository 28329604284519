import React, { Fragment, ReactNode } from 'react';

import { Permissions } from '../../permissions';

import { useCurrentUser } from '../../../auth/hooks/useAuth/useAuth';

import { ErrorPage } from '../../../app/components/ErrorPage';

interface CheckPermissionLayoutProps {
  action: string;
  appAction: Permissions | string;
  children: ReactNode;
}

function CheckPermissionLayout({
  action,
  appAction,
  children
}: CheckPermissionLayoutProps) {
  const currentUser = useCurrentUser();

  if (!currentUser) {
    return null;
  }

  return currentUser.hasPermissions(appAction) &&
    currentUser.hasPermissions(action) ? (
    <Fragment>{children}</Fragment>
  ) : (
    <ErrorPage statusCode={403} />
  );
}
export default CheckPermissionLayout;
