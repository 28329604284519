import React, { memo } from 'react';

import {
  FetchDownloadsErrorMessage,
  FetchDownloadsFetched
} from '../../../../../downloadsTypes';

import { AlertMessage } from '../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';

import {
  DownloadManagerBlockItem,
  DownloadManagerBlockItemDownloads
} from '../DownloadManagerBlockItem';

interface DownloadManagerBlockItemsProps {
  downloads: DownloadManagerBlockItemDownloads;
  downloadsFetched: FetchDownloadsFetched;
  downloadsError: FetchDownloadsErrorMessage;
}

function DownloadManagerBlockItems({
  downloads,
  downloadsFetched,
  downloadsError
}: DownloadManagerBlockItemsProps) {
  return (
    <div className="flex-1 overflow-y-auto p-4">
      <AlertMessage message={downloadsError} />
      <LoadingSkeleton loaded={downloadsFetched}>
        <ul className="space-y-2 w-full overflow-hidden">
          {downloads.map((download) => (
            <DownloadManagerBlockItem key={download.uuid} download={download} />
          ))}
        </ul>
      </LoadingSkeleton>
    </div>
  );
}

export default memo<DownloadManagerBlockItemsProps>(DownloadManagerBlockItems);
